<template>
  <dashboard>
    <el-row :gutter="15">
      <el-col :md="24" :xs="24">
        <div class="skill-search">
          <el-card
            v-loading="loading"
            class="box-card"
            style="min-height: 493px;"
          >
            <my-skills />
          </el-card>
        </div>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
import Dashboard from '~/components/Dashboard/Dashboard'
import MySkillsComponent from '~/components/Skills/MySkills'

export default {
  name: 'SkillSearch',

  components: {
    Dashboard,
    MySkills: MySkillsComponent,
  },
}
</script>
