<template>
  <dashboard>
    <h4 class="ml-1 mb-4 bold orange-text">My Skills</h4>
    <hr>
    <el-row :gutter="15">
      <el-col :xs="24" :md="24">
        <div class="skill-list">
          <el-card class="box-card" style="min-height: 0;">
              <!-- Skills if not null-->
                <p class="mb-4">Here is the list of your skills. Complete your skills so your profile will be ready to go once our companies are onboarded!</p>
              
                <div class="rate-wrap">
                  <h5 class="bold">Vue.js</h5>
                  <el-rate
                    v-model="value"
                    disabled
                    :texts="['Familiar but no hands on experience', 'Little hands on experience', 'Good hands on experience', 'In depth understanding/experience', 'Expert']"
                    show-text
                    text-color="#ff9900">
                  </el-rate>
                </div>
                <div class="rate-wrap">
                  <h5 class="bold">Laravel</h5>
                  <el-rate
                    v-model="value2"
                    disabled
                    :texts="['Familiar but no hands on experience', 'Little hands on experience', 'Good hands on experience', 'In depth understanding/experience', 'Expert']"
                    show-text
                    text-color="#ff9900">
                  </el-rate>
                </div>
                <div class="text-right">
                  <el-button type="success">Update My Skills</el-button>
                </div>

              <!-- Skills if null -->    
              <!-- <p class="orange-text bold text-center mb-3 mt-4">
                You have not selected any skills yet. Complete your profile by adding your skills.
              </p> 
              <div class="text-center mb-4">
                <el-button type="success">Add Skills</el-button>
              </div> -->
            
          </el-card>
        </div>
      </el-col>
    </el-row>
    
  </dashboard>
</template>

<script>
import Dashboard from "~/components/Dashboard/Dashboard";
// import Selected from "./components/Selected";
// import Selection from "./components/Selection";
// import PopularCategories from "./components/PopularCategories";
// import RelatedCategories from "./components/RelatedCategories";
// import { mapGetters } from "vuex";
// import {SKILLS, LOCAL_BASE_URL, USER } from '~/vuex/utils/constant'
// import axios from "axios";
// import _ from "lodash";

export default {
  components: {
    Dashboard,
    // Selected,
    // Selection,
    // PopularCategories,
    // RelatedCategories
  },
  created() {
    this.$store.dispatch("loadFreelancerCategory");
    this.$store.dispatch("loadFreelancerUserSkill");
  },
  data() {
    return {
      labelPosition: "left",
      formLabelWidth: "120px",
      value: "4",
      value2: "3",
      searchText: "",
      userSkillSelections: [],
      popular: [], // Popular categories
      related: [], // The related categories
      selected: [], // The selected skills with rating
      suggestion: [], // Suggestion base on searchText
      skillSelection: [], // The selection skill base on the suggestion that is selected
      skillProfileSelection: [], // The selection skill base on the suggestion that is selected and user skill profile
      searchNonce: "", // The nonce for search method, we can abort appending suggestion.
      skillNonce: "", // The nonce for handleSelect method, we can abort populating skill.
      skillNotfound: false, // trigger the link for dialoge
      dialogFormVisible: false, // Toggle dialog
      showRelated: false, // toggle related categories
      form: {
        skill: "",
        email: ""
      },
      rules: {
        skillname: [
          { required: true, message: "Skill name is required", trigger: "blur" }
        ],
        email: [
          { required: true, message: "Email is required", trigger: "blur" },
          {
            type: "email",
            message: "Please enter a valid email address",
            trigger: "blur"
          }
        ]
      },
      dummyPopular: [
        { id: 1, name: "Software Testing", type: "Category" },
        { id: 2, name: "IT Security", type: "Category" },
        { id: 3, name: "Networking", type: "Category" },
        { id: 4, name: "IT Infrastructure Management", type: "Category" },
        { id: 5, name: "Systems Administration", type: "Category" },
        { id: 6, name: "Software Development", type: "Category" },
        { id: 7, name: "Life Sciences", type: "Category" }
      ],
      /* dummySkill: {
        1: [
          { id: 1, name: "vue", type: "Skill" },
          { id: 2, name: "element", type: "Skill" },
          { id: 3, name: "cooking", type: "Skill" },
          { id: 4, name: "mint-ui", type: "Skill" },
          { id: 5, name: "vuex", type: "Skill" },
          { id: 6, name: "vue-router", type: "Skill" },
          { id: 7, name: "babel", type: "Skill" },
          { id: 8, name: "vue2", type: "Skill" },
          { id: 9, name: "element2", type: "Skill" },
          { id: 10, name: "cooking2", type: "Skill" },
          { id: 11, name: "mint-ui2", type: "Skill" },
          { id: 12, name: "vuex2", type: "Skill" },
          { id: 13, name: "vue-router2", type: "Skill" },
          { id: 14, name: "babel2", type: "Skill" },
          { id: 18, name: "vue3", type: "Skill" },
          { id: 19, name: "element3", type: "Skill" },
          { id: 20, name: "cooking3", type: "Skill" },
          { id: 21, name: "mint-ui3", type: "Skill" },
          { id: 22, name: "vuex3", type: "Skill" },
          { id: 23, name: "vue-router3", type: "Skill" },
          { id: 24, name: "babel3", type: "Skill" }
        ],
        2: [
          { id: 1, name: "vue", type: "Skill" },
          { id: 2, name: "element", type: "Skill" },
          { id: 3, name: "cooking", type: "Skill" },
          { id: 4, name: "mint-ui", type: "Skill" },
          { id: 5, name: "vuex", type: "Skill" },
          { id: 6, name: "vue-router", type: "Skill" },
          { id: 7, name: "babel", type: "Skill" }
        ]
      }, */
      skills: [],
      timeout: null
    };
  },
  computed: {
    /* ...mapGetters({
      category: "setCategory",
      userskill: "getUseSkill"
    }) */
    // ...mapGetters({
    //   info: "getFreelancerInfo",
    //   error: "getApiErrorResponse",
    //   success: "getPasswordAlert",
    //   status: "authStatus",
    //   userSkillList: "getUserSkill"
    // })
  },
  // methods: {
  //   async querySearch(queryString, callback) {
  //     if (queryString.length >= 3) {
  //       const localNonce = (this.searchNonce = new Object());
  //       queryString = queryString.trim();

  //       // Display no enter a text
  //       if (!queryString.length) {
  //         this.skillNotfound = false;
  //         return callback([{ name: "Please enter skill name." }]);
  //       }

  //       this.suggestion = await this.Search(queryString);

  //       // Abort update suggestion when there is another api request
  //       if (localNonce !== this.searchNonce) {
  //         // return;
  //       }

  //       var results = queryString
  //         ? this.suggestion.filter(this.createFilter(queryString))
  //         : this.suggestion;

  //       // Display no skill found
  //       if (_.isEmpty(results)) {
  //         this.skillNotfound = true;
  //         return callback([
  //           {
  //             name: "This skill is not found. would you like to add this skill?"
  //           }
  //         ]);
  //       }

  //       // call callback function to return suggestions
  //       this.skillNotfound = false;
  //       callback(results);
  //     }
  //   },
  //   createFilter(queryString) {
  //     return link => {
  //       return (
  //         link.skill_name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
  //       );
  //       //return link.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
  //     };
  //   },
  //   Search(queryString) {
  //     return new Promise(resolve => {
  //       Promise.all([
  //         //axios.get(config.api.url + "/api/v1/skills/categories/keyword")
  //         //axios.post("http://passport.local/api/skill")
  //         axios.get(`${LOCAL_BASE_URL}${SKILLS}/search?q=${queryString}`)
  //       ])
  //         .then(response => {
  //           resolve(response[0].data.data);
  //           /*
  //           response = [
  //             {
  //               data: [
  //                 { id: 1, name: "Software Testing", type: "Category" },
  //                 { id: 2, name: "IT Security", type: "Category" },
  //                 { id: 3, name: "Networking", type: "Category" },
  //                 {
  //                   id: 4,
  //                   name: "IT Infrastructure Management",
  //                   type: "Category"
  //                 },
  //                 { id: 5, name: "Systems Administration", type: "Category" },
  //                 { id: 6, name: "Software Development", type: "Category" },
  //                 { id: 7, name: "Life Sciences", type: "Category" }
  //               ]
  //             }
  //           ];
  //           console.log(response[0].data);
  //           resolve(response[0].data);
  //           */
  //         })
  //         .catch(err => {
  //           console.log(err);
  //         });
  //     });
  //   },
  //   async showSkill(items) {

  //     if (!items.skill_id) return;

  //     this.userSkillSelections = this.userSkillList; // Assign user skills from skill profile
  //     console.log('Here');
  //     console.log(this.userSkillSelections);

  //     this.searchText = items.skill_name;

  //     const localNonce = (this.skillNonce = new Object());
  //     const skillSelection = await this.searchSkillByCategory([items]);

  //     // Abort populating the selection when there is another api request
  //     if (localNonce !== this.skillNonce) {
  //       return;
  //     }

  //     if (Array.isArray(this.skillSelection)) {
  //       this.skillSelection.push(skillSelection[0]);
  //     } else {
  //       // Populate selection
  //       this.skillSelection = skillSelection;
  //     }

  //     // map skill selection and user skill profile
  //     let mapselection = this.mapUserSelection(
  //       this.skillSelection,
  //       this.userSkillSelections
  //     );

  //     this.skillProfileSelection.push(mapselection);

  //     // Show related begin here
  //     this.showRelated = false;
  //     //this.related = this.popular;
  //   },
  //   mapUserSelection(skillSelection, userSkillSelections) {
  //     let i;
  //     let mapSelection = {};
  //     let currentSkill;
  //     for (i = 0; i < skillSelection.length; i++) {
  //         currentSkill = userSkillSelections.length == 0 ? null : userSkillSelections[i].skill_id;
  //         mapSelection["skill_id"] = skillSelection[i].skill_id;
  //         mapSelection["skill_name"] = skillSelection[i].skill_name;
  //         mapSelection["skill_rating"] =
  //           skillSelection[i].skill_id == currentSkill
  //             ? userSkillSelections[i].rating
  //             : "1";
  //     }

  //     return mapSelection;
  //   },
  //   async handleSelect(item) {
  //     if (!item.id) return;

  //     this.searchText = item.name;

  //     const localNonce = (this.skillNonce = new Object());
  //     const skillSelection = await this.searchSkillByCategory();

  //     // Abort populating the selection when there is another api request
  //     if (localNonce !== this.skillNonce) {
  //       return;
  //     }

  //     // Populate selection
  //     this.skillSelection = skillSelection;

  //     // Show related begin here
  //     this.showRelated = false;
  //     //this.related = this.popular;
  //   },
  //   searchSkillByCategory(items) {
  //     let self = this;

  //     // The http request should have ond endpoints
  //     // 1 for skill table
  //     // axios.get(config.api.url + '/api/v1/skill/search?q=text')
  //     // the results should have a relationship for Category
  //     // the relationships set as related category

  //     //Sample  using timeout

  //     return new Promise(resolve => {
  //       clearTimeout(this.timeout);

  //       this.timeout = setTimeout(function() {
  //         //const _indx = Math.floor(Math.random() * 2) + 1;
  //         //resolve(self.dummySkill[_indx]);
  //         self.skills = items;
  //         resolve(self.skills);
  //       }, 1000 * Math.random());
  //     });
  //   },
  //   rating(data) {
  //     let selectionKey = _.findIndex(this.skillSelection, { id: data.id });
  //     // Update the rate on selection
  //     if (selectionKey !== -1) {
  //       this.$set(this.skillSelection[selectionKey], "rate", data.value);
  //     }

  //     let selectedKey = _.findIndex(this.selected, { id: data.id });
  //     // update the rate on selected
  //     if (selectedKey !== -1) {
  //       this.$set(this.selected[selectedKey], "rate", data.value);
  //     }
  //   },
  //   rates(data) {
  //      axios
  //       .post(
  //         LOCAL_BASE_URL + `/api/v1/freelancer/${this.info.freelancer_id}/skills/${data.id}`,
  //         {
  //           rating: data.value, skill_order: 1 // no instructions yet for skill order
  //         },
  //         {
  //           headers: {
  //             Authorization: "Bearer " + USER.authorization.access_token
  //           }
  //         }
  //       )
  //       .then(response => {
  //         console.log(response);
  //         //this.$emit("subscriptionUpdate", response.data);
  //       }); 
  //   },
  //   setSelected(item) {
  //     // Check if slection already selected
  //     let itemKey = _.findIndex(this.selected, { id: item.id });
  //     // Item exist in selected, no need to push the item
  //     if (itemKey !== -1) return;

  //     // Push the new selected item
  //     this.selected.push({
  //       ...item
  //     });
  //   },
  //   removeSelected(itemid) {
  //     let itemKey = _.findIndex(this.selected, { id: itemid });
  //     // abort when the selected to removed did not exists
  //     if (itemKey == -1) return;

  //     // Remove the selected
  //     let _selected = this.selected;
  //     _.remove(_selected, function(selected) {
  //       return selected.id == itemid;
  //     });
  //     this.$set(this.selected, _selected);

  //     this.$message({
  //       type: "success",
  //       message: "Delete completed"
  //     });
  //   },
  //   removeConfirmation(itemid) {
  //     this.$confirm(
  //       "This will permanently delete the skill. Continue?",
  //       "Warning",
  //       {
  //         confirmButtonText: "OK",
  //         cancelButtonText: "Cancel",
  //         type: "warning"
  //       }
  //     )
  //       .then(() => {
  //         this.removeSelected(itemid);
  //       })
  //       .catch(() => {});
  //   },
  //   formSubmission() {
  //     let error = false;
  //     // Revalidate if no error before submitting.
  //     this.$refs["skillForm"].validate(valid => {
  //       if (!valid) {
  //         error = true;
  //       }
  //     });
  //     // Abort there is an error
  //     if (error) return;
  //     this.dialogFormVisible = false;

  //     // Axios process begins here
  //   }
  // },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.rate-wrap>.el-rate>.el-rate__text {
  font-size: 12px;
}
.rate-wrap {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  display: block;
  padding: 8px 16px;
  border-radius: 4px;
  border-left: 5px solid #E87722;
  width: 100%;
}
</style>
